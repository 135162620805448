<template>
    <!-- Ana görüntüleyici konteyneri -->
    <div class="viewer-container">
        <!-- İçerik konteyneri -->
        <div id="container">
            <!-- Slayt gösterisi - her slayt arası 0 boşluk ve tek slayt görünümü -->
            <swiper :slides-per-view="1" :space-between="0" @swiper="onSwiper" @slideChange="onSlideChange">
                <!-- Slayt 1: Gözlük çıkarma adımı -->
                <swiper-slide>
                    <div class="step">
                        <!-- Adım görseli -->
                        <div class="image">
                            <div style="background-image: url('/form/step1.png')"></div>
                        </div>
                        <!-- Adım başlığı -->
                        <div class="title">Gözlükleri çıkarın</div>
                        <!-- Adım açıklaması -->
                        <div class="desc">Aksi halde tuhaf görünecek, bize inanın.</div>
                        <!-- İleri/geri navigasyon okları -->
                        <div class="arrows">
                            <div class="prev" @click="back('list')">
                                <img src="/arrow-prev.png" />
                            </div>
                            <div @click="nextStep" class="next">
                                <img src="/arrow-next.png" />
                            </div>
                        </div>
                        <!-- İlerleme göstergesi -->
                        <div class="progress-container">
                            <div class="current">{{currentStep}}</div>
                            <div class="progress-steps">
                                <div v-for="step in 5" :key="step" :class="['progress-step', { 'active': step <= currentStep }]"></div>
                            </div>
                            <div>{{totalSteps}}</div>
                        </div>
                        <!-- Logo -->
                        <div class="logo"><img src="/logo-horizontal.png" /></div>
                    </div>
                </swiper-slide>

                <!-- Slayt 2: Saç toplama adımı -->
                <swiper-slide>
                    <div class="step">
                        <div class="image">
                            <div style="background-image: url('/form/step2.png')"></div>
                        </div>
                        <div class="title">Saçınızı toplayın</div>
                        <div class="desc">Yüzünüzün saçlarla kaplı olmadığından emin olun.</div>
                        <div class="arrows">
                            <div @click="prevStep" class="prev">
                                <img src="/arrow-prev.png" />
                            </div>
                            <div @click="nextStep" class="next">
                                <img src="/arrow-next.png" />
                            </div>
                        </div>
                        <div class="progress-container">
                            <div class="current">{{currentStep}}</div>
                            <div class="progress-steps">
                                <div v-for="step in 5" :key="step" :class="['progress-step', { 'active': step <= currentStep }]"></div>
                            </div>
                            <div>{{totalSteps}}</div>
                        </div>
                        <div class="logo"><img src="/logo-horizontal.png" /></div>
                    </div>
                </swiper-slide>

                <!-- Slayt 3: Aydınlatma adımı -->
                <swiper-slide>
                    <div class="step">
                        <div class="image">
                            <div style="background-image: url('/form/step3.png')"></div>
                        </div>
                        <div class="title">İyi Aydınlatma Sağlayın</div>
                        <div class="desc">Yüzünüzdeki gölgelerden kaçının. Bir pencerenin önünde durun.</div>
                        <div class="arrows">
                            <div @click="prevStep" class="prev">
                                <img src="/arrow-prev.png" />
                            </div>
                            <div @click="nextStep" class="next">
                                <img src="/arrow-next.png" />
                            </div>
                        </div>
                        <div class="progress-container">
                            <div class="current">{{currentStep}}</div>
                            <div class="progress-steps">
                                <div v-for="step in 5" :key="step" :class="['progress-step', { 'active': step <= currentStep }]"></div>
                            </div>
                            <div>{{totalSteps}}</div>
                        </div>
                        <div class="logo"><img src="/logo-horizontal.png" /></div>
                    </div>
                </swiper-slide>

                <!-- Slayt 4: Kamera pozisyonu adımı -->
                <swiper-slide>
                    <div class="step">
                        <div class="image">
                            <div style="background-image: url('/form/step4.png')"></div>
                        </div>
                        <div class="title">Doğrudan kameraya bakın</div>
                        <div class="desc">Akıllı telefonu başınızın hizasında tutun, aşağıda değil.</div>
                        <div class="arrows">
                            <div @click="prevStep" class="prev">
                                <img src="/arrow-prev.png" />
                            </div>
                            <div @click="nextStep" class="next">
                                <img src="/arrow-next.png" />
                            </div>
                        </div>
                        <div class="progress-container">
                            <div class="current">{{currentStep}}</div>
                            <div class="progress-steps">
                                <div v-for="step in 5" :key="step" :class="['progress-step', { 'active': step <= currentStep }]"></div>
                            </div>
                            <div>{{totalSteps}}</div>
                        </div>
                        <div class="logo"><img src="/logo-horizontal.png" /></div>
                    </div>
                </swiper-slide>

                <!-- Slayt 5: Fotoğraf çekimi adımı -->
                <swiper-slide>
                    <div class="step">
                        <div class="image">
                            <div style="background-image: url('/form/step5.png')"></div>
                        </div>
                        <div class="title">3 fotoğraf çekmeniz gerekecek</div>
                        <div class="desc">Ön, sol ve sağ taraf olacak şekilde.</div>
                        <div class="arrows">
                            <div @click="prevStep" class="prev">
                                <img src="/arrow-prev.png" />
                            </div>
                            <div @click="nextStep" class="take-image">
                                <div>Tara</div>
                            </div>
                        </div>
                        <div class="progress-container">
                            <div class="current">{{currentStep}}</div>
                            <div class="progress-steps">
                                <div v-for="step in 5" :key="step" :class="['progress-step', { 'active': step <= currentStep }]"></div>
                            </div>
                            <div class="current">{{totalSteps}}</div>
                        </div>
                        <div class="logo"><img src="/logo-horizontal.png" /></div>
                    </div>
                </swiper-slide>

                <!-- Slayt 6: Cinsiyet seçimi adımı -->
                <swiper-slide>
                    <div class="step">
                        <div class="image">
                            <div :style="`background-image: url(${gender_url})`"></div>
                        </div>
                        <div class="flex-wrapper">
                            <div class="title" @click="gender = 'male'" :class="gender !== 'male' ? 'not' : ''">Erkek</div>
                            <div class="title" @click="gender = 'female'" :class="gender !== 'female' ? 'not' : ''">Kadın</div>
                        </div>
                        <div class="desc">Vücut tipini seçiniz.</div>
                        <div class="arrows solo">
                            <div @click="lastStep" class="next">
                                <img src="/arrow-next.png" />
                            </div>
                        </div>
                        <div class="logo right"><img src="/logo-horizontal.png" /></div>
                    </div>
                </swiper-slide>

                <!-- Slayt 7: Kamera bileşeni -->
                <swiper-slide>
                    <CameraComponent :gender="gender" :info="initStep" :completed="completed" :uid="uid" v-if="currentStep === 7" />
                </swiper-slide>
            </swiper>
            <!-- Atlama butonu - son adımda gösterilmez -->
            <div class="skip" v-if="currentStep !== 7" @click="imageStep">Atla</div>
        </div>
    </div>
</template>
<script>
// Swiper Vue.js bileşenlerini içe aktar
import { Swiper, SwiperSlide } from 'swiper/vue';
import CameraComponent from '@/components/CameraComponent.vue'
// Swiper stillerini içe aktar
import 'swiper/css';
export default {
    name: 'CreateAvatarForm',
    props: {
        uid: String, // Kullanıcı ID'si
        completed: Function, // Form tamamlandığında çağrılacak fonksiyon
        goBack: Function // Geri dönüş fonksiyonu
    },
    components: {
        Swiper, // Slayt gösterisi bileşeni
        SwiperSlide, // Tekil slayt bileşeni
        CameraComponent // Kamera bileşeni
    },
    data() {
        return {
            swiperOptions: {
                // slidesPerView, loop vb. Swiper seçenekleri
                // Daha fazla seçenek Swiper dokümantasyonunda bulunabilir
                slidesPerView: 1,
                loop: false,
            },
            currentStep: 1, // Mevcut adım
            totalSteps: 5, // Toplam adım sayısı
            swiper: null, // Swiper örneği
            gender: 'female', // Cinsiyet seçimi (male/female)
            formData: {
                // Her adım için form verisi
                step1Data: {},
                step2Data: {},
                step3Data: {},
                step4Data: {},
                step5Data: {},
            }
        };
    },
    computed: {
        gender_url() {
            // Seçilen cinsiyete göre görsel URL'si
            return this.gender === 'male' ? '/form/male.png' : '/form/female.png'
        }
    },
    methods: {
        back(str) {
            // Belirtilen sayfaya geri dön
            this.goBack(str);
        },
        lastStep() {
            // Son adıma git
            this.currentStep = 7;
            const lastIndex = this.swiper.slides.length - 1;
            this.swiper.slideTo(lastIndex);
        },
        imageStep() {
            // Görsel adımına git
            this.currentStep = 6;
            const lastIndex = this.swiper.slides.length - 2;
            this.swiper.slideTo(lastIndex);
        },
        initStep() {
            // İlk adıma dön
            this.currentStep = 1;
            this.swiper.slideTo(0);
        },
        onSwiper(swiper) {
            // Swiper örneğini kaydet
            console.log('swiper', swiper);
            this.swiper = swiper;
        },
        onSlideChange() {
            // Slayt değişimini logla
            console.log('slide change');
        },
        nextStep() {
            // Sonraki slayta geç
            this.swiper.slideNext();
            this.currentStep++;
        },
        prevStep() {
            // Önceki slayta dön
            this.swiper.slidePrev();
            this.currentStep--;
        },
        // Form gönderimi, doğrulama vb. için ek metodlar eklenebilir
    }
}
</script>

<style scoped>
.take-image {
    background: #ABABAB;
    border-radius: 18px;
    color: #fff;
    line-height: 2;
    margin-right: 20px;
    cursor: pointer;
}

.logo img {
    max-width: 90px;
}

.viewer-container,
.swiper-container {
    height: 100%;
}

.title {
    font-size: 32px;
    font-weight: 500;
    padding: 10px 0;
    max-width: 80%;
    text-align: center;
    cursor: pointer;
}

.desc {
    font-size: 15px;
    font-weight: 200;
    padding: 0;
}

.step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 80px);
    padding: 60px 0 20px 0;
}

.step div {
    color: #f7f7f7;
}

.arrows {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.arrows.solo {
    display: flex;
    justify-content: center;
    width: 100%;
}

.arrows>div {
    padding: 0 20px;
}

.arrows img {
    width: 30px;
}

.swiper {
    height: 100%;
}

.image,
.desc {
    flex: 1;
}

.image>div {
    width: 250px;
    height: 250px;
    margin-bottom: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.progress-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 120px;
    gap: 10px;
}

.progress-steps {
    display: flex;
    width: 100%;
    max-width: 500px;
    /* Adjust based on your preference */
    overflow: hidden;
    border-radius: 5px;
}

.progress-step {
    flex-grow: 1;
    height: 5px;
    /* Height of the progress bar */
    background-color: #e0e0e0;
    /* Default/inactive step color */
    margin: 0;
    /* Spacing between steps */
}

.progress-step:first-child {
    margin-left: 0;
}

.progress-step:last-child {
    margin-right: 0;
}

.progress-container .current {
    color: #4caf50;
}

.progress-step.active {
    background-color: #4caf50;
    /* Active step color */
}

.skip {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #f7f7f7;
    font-size: 14px;
    cursor: pointer;
    z-index: 2;
}

.flex-wrapper {
    display: flex;
    gap: 20px;
}

.title.not {
    color: #ABABAB;
}
</style>
