<template>
    <div class="viewer-container">
        <div id="container">
            <!-- Geri dönüş butonu -->
            <BackButton :go-back="back" />
            <!-- Avatar oluşturma başlığı -->
            <div class="title bold">Avatarınızı oluşturalım</div>
            <!-- Talimatlar listesi -->
            <div class="desc-list">
                <ul>
                    <li><span>1.</span>QR kodunu mobil cihazınızla tarayın</li>
                    <li><span>2.</span>Selfie çekmek için talimatları izleyin</li>
                    <li><span>3.</span>Masaüstü bilgisayarınıza dönün</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>

import BackButton from "@/components/BackButton.vue";
export default {
    name: 'CreateAvatar',
    // Kullanılan bileşenler
    components: {
        BackButton
    },
    // Bileşen özellikleri
    props: {
        goBack: Function, // Geri dönüş fonksiyonu
    },
    // Bileşen verisi
    data() {
        return {

        }
    },
    // Bileşen metodları
    methods: {
        // Liste görünümüne geri dönüş metodu
        back() {
            this.goBack('list');
        }
    }
}
</script>
<style scoped>
/* Talimatlar listesi stilleri */
.desc-list {
    padding-top: 40px;
}

.desc-list ul {
    list-style: none;
    max-width: 320px;
    margin: 0 auto;
}

.desc-list ul li span {
    padding-right: 10px;
}
.desc-list ul li {
    font-size: 15px;
    font-weight: 300;
    padding-bottom: 15px;
    color: #f7f7f7;
    text-align: left;
}
/* Logo stilleri */
.logo {
    width: 150px;
    margin: 0 auto;
    padding: 10px 0 0 0;
}
/* Görsel stilleri */
img {
    max-width: 100%;
    height: auto;
}
/* Başlık stilleri */
.title {
    font-size: 30px;
    padding: 2rem 0 10px 0;
    color: white;
}
/* Açıklama stilleri */
.desc {
    font-size: 16px;
    padding: 0px 0 10px 0;
    color: white;
}
/* Kalın yazı stili */
.bold {
    font-weight: 500;
}
/* Konteyner stilleri */
#container {
    text-align: center;
}
/* Ayraç stili */
.or {
    color: #f7f7f7;
    padding: 20px 0;
}
/* Oluştur butonu stilleri */
.create-button {
    background: transparent;
    border: 1px solid #f7f7f7;
    color: #f7f7f7;
    padding: 10px 20px;
    max-width: 200px;
    border-radius: 10px;
    margin: 0 auto;
    cursor: pointer;
    transition: all .5s;
}
/* Oluştur butonu hover efekti */
.create-button:hover {
    background: #f7f7f7;
    color: #333;
}
</style>