<template>
    <div class="viewer-container" :class="selectedMenu">
      <!-- Ana menüdeyken geri dönüş butonunu göster -->
      <BackButton v-if="selectedMenu === ''" :go-back="back" />
      <!-- Detay görünümündeyken onay butonunu göster -->
      <ConfirmButton v-if="isDetail && selectedMenu === ''" :go-back="handleExportAvatar" />
      <!-- Asset listesi - detay görünümünde ve asset menüsü seçiliyken gösterilir -->
      <AssetList
          v-if="isDetail && (selectedMenu === '' || selectedMenu === 'asset')"
          :panel-visible="hairPanelVisible"
          :hair-clicked="assetSelected"
          :hair-color-changed="colorSelected"
          :toggle="assetOpen"
          :list="assetList">
      </AssetList>
      <!-- Body listesi - detay görünümünde ve body menüsü seçiliyken gösterilir -->
      <BodyList
          v-if="isDetail && (selectedMenu === '' || selectedMenu === 'body')"
          :class="selectedMenu === 'body' ? 'active' : ''"
          :panel-visible="bodyPanelVisible"
          :body-clicked="bodySelected"
          :toggle="bodyOpen"
          :list="bodyList">
      </BodyList>

      <!-- 3D sahnenin gösterileceği iframe konteyneri -->
      <div ref="scene" class="iframe-container"></div>
      <!-- Yükleme durumunda gösterilecek loading spinner -->
      <div v-if="isLoading" class="spinner-wrapper2">
        <div class="spinner2"></div>
        <MatchyLogo3 />
      </div>
    </div>
</template>

<script>
// Gerekli bileşenlerin ve kütüphanelerin import edilmesi
import MatchyLogo3 from "@/components/MatchyLogo3.vue";
import { AvaturnSDK } from '@avaturn/sdk';
import { markRaw } from 'vue';
import BackButton from "@/components/BackButton.vue";
import ConfirmButton from "@/components/ConfirmButton.vue";
import AssetList from "@/components/AssetList.vue";
import BodyList from "@/components/BodyList.vue";

export default {
  // Kullanılan bileşenlerin tanımlanması
  components: {
    BodyList,
    AssetList,
    BackButton,
    ConfirmButton,
    MatchyLogo3
  },
  // Bileşene gelen prop'ların tanımlanması
  props: {
    url: {
      type: String,
      default: null
    },
    goBack: Function,
    avatarLoaded: Function,
    reset: Function
  },
  // Bileşenin lokal state'inin tanımlanması
  data() {
    return {
      sdk: null, // Avaturn SDK instance'ı
      assetOpen: false, // Asset panelinin açık/kapalı durumu
      bodyOpen: false, // Body panelinin açık/kapalı durumu
      bodyList: [], // Mevcut vücut tiplerinin listesi
      assetList: [], // Mevcut asset'lerin listesi
      isLoading: false, // Yükleme durumu
      isDetail: false, // Detay görünümünün durumu
      gender: null, // Avatar cinsiyeti
      selectedMenu: '', // Seçili menü
      token: process.env.VUE_APP_API_TOKEN, // API token'ı
      filterIds: [
        "018fce86-26e1-754c-b352-ac4a8e12ef41",
        "018fce85-e18e-7503-8896-38264e1c9761"
      ],
      clothIds: [
        "018fcea4-dd59-772d-973b-56946af57455",
        "018fcea4-4f99-7ead-810c-eeb9b6ef4351",
      ],
    };
  },
  // Bileşen mount edildiğinde SDK'yı başlat
  async mounted() {
    await this.initSdk();
  },
  // Bileşen unmount edildiğinde temizlik işlemleri
  beforeUnmount() {
  },
  // Hesaplanmış özellikler
  computed: {
    // Filtre edilmiş asset'ler
    filteredItems() {
      return this.assetList.filter(
          (item) => !this.filterIds.includes(item.id) && !this.clothIds.includes(item.id)
      );
    },
    // Boş asset'ler
    emptyItems() {
      return this.assetList.filter((item) => this.filterIds.includes(item.id));
    },
    // Kıyafet asset'leri
    clothItems() {
      return this.assetList.filter((item) => this.clothIds.includes(item.id));
    },
  },
  methods: {
    // Body panelinin görünürlüğünü kontrol eden method
    bodyPanelVisible (value) {
      if (value) {
        this.selectedMenu = 'body';
      } else {
        this.selectedMenu = '';
      }
      this.bodyOpen = value;
      if (value && this.assetOpen) {
        this.assetOpen = false;
      }
    },
    // Saç rengini değiştiren method
    colorSelected (color) {
      console.log('sdk', this.sdk, color);
      this.sdk.setParam("hair_color", color);
    },
    // Saç panelinin görünürlüğünü kontrol eden method
    hairPanelVisible(value) {
      if (value) {
        this.selectedMenu = 'asset';
      } else {
        this.selectedMenu = '';
      }
      this.assetOpen = value;
      console.log('hair is open', value, this.bodyOpen);
      if (value && this.bodyOpen) {
        this.bodyOpen = false;
      }
    },
    // Vücut tipini değiştiren method
    bodySelected (body) {
      this.sdk.setActiveBody(body.id).then(() => {
        console.log(`Body changed: ${body.id}`);
      });
    },
    // Geri dönüş işlemini yöneten method
    back () {
      console.log('vars', this.bodyOpen, this.assetOpen, this.isDetail);
      if (this.bodyOpen) {
        this.bodyOpen = false;
      } else if (this.assetOpen) {
        this.assetOpen = false;
      } else {
        this.goBack('list');
      }
    },
    // Avatar dışa aktarma işlemini yöneten method
    async handleExportAvatar () {                          // Avatar dışa aktarma işlemini başlatır
      this.isLoading = true;                              // Yükleme durumunu aktif eder
      await this.assetSelected(this.emptyItems.pop());    // Boş asset seçerek avatarı temizler
      setTimeout(() => {                                   // 500ms gecikme ile işlemi başlatır
        console.log(`Calling sdk.exportAvatar()`);        // Export işleminin başladığını loglar
        this.sdk.exportAvatar().then((res) => {           // SDK'nın exportAvatar metodunu çağırır
          console.log(res);                               // Export sonucunu loglar
          console.log("[then] Avatar exported: ", res);   // Export başarılı olduğunu loglar
          this.isLoading = false;                         // Yükleme durumunu kapatır
          this.avatarLoaded(res);                         // Sonucu parent component'e iletir
        }).catch(() => {                                  // Hata durumunda
          this.isLoading = false;                         // Yükleme durumunu kapatır
        });
      }, 500);
    },
    // Asset seçimini yöneten method
    async assetSelected(asset) {
      try {
        if (asset) {
          await this.sdk.setActiveAsset(asset.id);
          console.log(`Asset changed: ${asset.id}`);
        }
      } catch (error) {
        console.error(`Error changing asset ${asset.id}:`, error);
        throw error;
      }
    },
    // Avaturn SDK'yı başlatır ve temel yapılandırmaları gerçekleştirir
    async initSdk () {
      // Yükleme durumunu kapatır
      this.isLoading = false;
      // Yeni bir AvaturnSDK örneği oluşturur ve reactive olmayan şekilde saklar
      this.sdk = markRaw(new AvaturnSDK());
      // SDK'yı verilen parametrelerle başlatır
      this.sdk.init(this.$refs.scene, {
        url: this.url,
        iframeClassName: 'iframe-wrapper', 
        token: this.token,
        disableUi: true // Varsayılan UI'ı devre dışı bırakır
      }).then((result) => {
        console.log('loaded', result);
        this.isLoading = false;
        // SDK yüklendiğinde tetiklenecek olay dinleyicisi
        this.sdk.on("load", (data) => {
          console.log("[callback] load: ", data);
          // Mevcut vücut tiplerini getirir
          this.sdk.getBodyList().then((list) => {
            console.log('body', list);
            // body_2 ID'li vücut tipini filtreler
            this.bodyList = list.filter(item => item.id !== 'body_2');
            // İlk vücut tipinin cinsiyetini kaydeder
            this.gender = list[0].gender;
            this.isDetail = true;
            // Mevcut asset'leri (kıyafet, saç vb.) getirir
            this.sdk.getAssetList().then(async (list) => {
              this.assetList = list;
              console.log('assets', list);
              // İlk kıyafeti avatara uygular
              await this.assetSelected(this.clothItems.pop());
            });
          });
        });
      }).catch((reason) => {
          // SDK başlatma hatalarını yakalar ve loglar
          console.log(`[Avaturn SDK Error]: ${reason}`);
          this.isLoading = false;
        });
    },
  }
};
</script>
