<template>
  <div class="viewer-container">
    <!-- Ayarlar bileşeni, avatar oluşturma fonksiyonunu props olarak alır -->
    <SettinsComponent :create-avatar="createAvatar"/>
    <!-- Kamera pozisyonunu gösteren div -->
    <div ref="cameraPositionDisplay" class="camera-position"></div>
    <!-- 3D sahnenin render edileceği konteyner -->
    <div id="container" ref="container"></div>
    <!-- Tooltip bileşeni, showTooltip değişkenine bağlı olarak görünürlüğü kontrol edilir -->
    <div ref="tooltip" class="tooltip" v-show="showTooltip">
      <p>{{ tooltipContent }}</p>
    </div>
    <!-- Alt liste bileşeni, ana ürün varsa ve yeniden yükleme yoksa gösterilir
         Önerilen ürünleri ve ürün seçim fonksiyonlarını props olarak alır -->
    <HideableBottomList ref="bottomList" v-if="mainProduct && !reloads" :toggled="listToggled" :product-selected="productSelected"
                        :product-name="mainProduct.name" :recommended="recommended_products"></HideableBottomList>
  </div>
</template>
<script>
// Ayarlar bileşeni - Kullanıcı ayarlarını yönetmek için kullanılır
import SettinsComponent from "./SettinsComponent.vue";
// Alt liste bileşeni - Ürün listesini göstermek için kullanılır
import HideableBottomList from "./HideableBottomList.vue";

// Three.js - 3D grafik işlemleri için ana kütüphane
import * as THREE from 'three';
// Tween.js - Animasyonlar için kullanılan kütüphane
import * as TWEEN from '@tweenjs/tween.js';
// GLTFLoader - 3D modelleri yüklemek için kullanılan yükleyici
import {GLTFLoader} from 'three/addons/loaders/GLTFLoader.js';
// OrbitControls - Kamera kontrollerini sağlayan bileşen
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
//import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';
import {markRaw} from 'vue';


export default {
  components: {
    SettinsComponent,
    HideableBottomList
  },
  props: {
    // Avatar modeli için vücut URL'si
    body: {
      type: String,
      default: null
    },
    // Avatarın cinsiyeti
    avatarGender: {
      type: String,
      default: null
    },
    // Vücut tipi - varsayılan olarak 'body_1'
    bodyType: {
      type: String,
      default: 'body_1'
    },
    // Avatar oluşturma fonksiyonu
    createAvatar: Function,
    // Ana ürün nesnesi
    product: Object,
    // Tüm ürünlerin listesi
    productlist: Array
  },
  data() {
    return {
      // Yeniden yükleme durumu
      reloads: false,
      // Three.js sahne nesnesi
      scene: null,
      // Logo yükleme durumu
      loadLogo: null,
      // Billboard nesneleri
      billboard: null,
      billboardContext: null,
      billboardCanvas: null,
      billboardVisible: false,
      // Three.js temel nesneler
      renderer: null,
      camera: null,
      mixer: null,
      TWEEN: null,
      controls: null,
      animationGroup: null,
      clock: null,
      // Avatar ve model nesneleri
      currentAvatar: null,
      walkAction: null,
      bodyModel: null,
      productModels: [],
      productDots: [],
      // Tooltip özellikleri
      showTooltip: false,
      tooltipContent: '',
      // Fare ve ışın izleme
      mouse: null,
      raycaster: null,
      rayLine: null,
      rayLineMaterial: null,
      // Ürün verileri
      mainProduct: null,
      products: [],
      recommended_products: [],
      // Kamera pozisyonları
      cameraPositions: {
        // Başlangıç pozisyonu
        init: {
          x: 0,
          y: 3,
          z: 5,
          controlX: 0,
          controlY: .5,
          controlZ: 0
        },
        // Varsayılan görünüm
        default: {
          x: 0.4,
          y: 1.25,
          z: 3.78,
          controlX: 0,
          controlY: 0.4,
          controlZ: 0
        },
        // Yüz görünümü
        face: {
          x: 0.25,
          y: 1.18,
          z: 2.80,
          controlX: 0,
          controlY: 0.8,
          controlZ: 0
        },
        // Panel görünümü
        panel: {
          x: -0.5,
          y: 1.38,
          z: 3,
          controlX: 0.2,
          controlY: 1,
          controlZ: 0.8
        }
      }
    };
  },
  async mounted() {
    // Logo ekle
    this.addLogo()
    // Ürün listesini sıfırla
    this.products = []
    // Ana ürünü ayarla
    this.mainProduct = this.product;
    // Ürün listesindeki her ürün için yükleme durumlarını ayarla
    Object.keys(this.productlist).forEach(key => {
      const pr = this.productlist[key];
      pr.loaded = false;
      pr.loading = false;

      this.recommended_products.push(pr);
    });
    // Three.js sahnesini başlat
    this.initThree();
    // Avatar modelini yükle
    this.loadBody();
    // Billboard oluştur
    this.billboard = this.createBillboard();
    // Fare tıklama olayını dinle
    window.addEventListener('click', this.onMouseMove, false);
  },
  beforeUnmount() {
    // Bellek sızıntılarını önlemek için temizlik
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('click', this.onMouseMove);

    // Renderer'ı temizle
    if (this.renderer) {
      this.renderer.dispose();
    }
  },
  methods: {
    animateCamera(newPosition, newControlsTarget) {
      new TWEEN.Tween(this.camera.position)
          .to({x: newPosition.x, y: newPosition.y, z: newPosition.z}, 1000) // milisaniye cinsinden süre
          .easing(TWEEN.Easing.Quadratic.Out) // yumuşatma fonksiyonu
          .start();

      // Kontrollerin hedefini canlandır
      new TWEEN.Tween(this.controls.target)
          .to({x: newControlsTarget.x, y: newControlsTarget.y, z: newControlsTarget.z}, 1000)
          .easing(TWEEN.Easing.Quadratic.Out)
          .onUpdate(() => this.controls.update()) // her tween güncellemesinde kontrolleri güncelle
          .start();
    },
    // Ürün seçildiğinde çağrılır
    productSelected(product) {
      if (product.loaded) {
        // Ürün zaten yüklüyse kaldır
        this.removeProduct(product);
      } else {
        // Ürünün vücut bölgesi ve önceliğine göre diğer ürünleri bul
        this.findProductsByBodyType(product.body_part, product.priority);
        // Yeni ürünü yükle
        this.loadProduct(product);
      }
    },
    // Belirli vücut bölgesi ve önceliğe sahip ürünleri bulur
    findProductsByBodyType(body_part, priority) {
      console.log('clearing if possingle', this.productModels, body_part, priority)
      Object.entries(this.productModels).forEach((pro) => {
        const product = this.products[pro[0]];
        console.log('checkin', product)
        console.log('body part', body_part, 'priority', priority, product);
        // Aynı vücut bölgesi ve önceliğe sahip ürünleri kontrol et
        if ((product.body_part === body_part && product.priority === priority) ||
            // Tam vücut ürünü için üst/alt beden kontrolü
            (body_part === "full body" && (product.body_part === "üst beden" || product.body_part === "alt beden") && priority == product.priority) ||
            // Üst/alt beden için tam vücut kontrolü  
            product.body_part === "full body" && (body_part === "üst beden" || body_part === "alt beden") && priority === product.priority) {
          console.log('in check');
          // Eşleşen ürünü seç
          this.productSelected(product);
        }
      });
    },
    // Ürün modeli için URL'yi alır
    getModelUrl(product) {
      console.log('get model url', product, this.bodyType)
      if (this.bodyType !== 'body_1') {
        // Vücut tipinden filtre ID'sini ayıkla
        const filter = this.bodyType.split('_')[1]
        console.log('body id', filter);

        // Verilen filtre ID'sine göre ürünün vücut modellerini filtrele
        const filtered_model = product.body_models.filter((model) => {
          if (model.body === filter) {
            return model
          }
        })
        // Filtrelenmiş modelin URL'sini oluştur
        const result = `${process.env.VUE_APP_API_URL}/posts/photo/${filtered_model[0].url}`
        console.log('filtered', filtered_model)
        return result
      } else {
        // Varsayılan vücut tipi için ilk modeli döndür
        return product.models[0]
      }
    },
    // Kamera pozisyonunu belirtilen konuma ayarlar
    setCamera(pos) {
      console.log('camera set', pos);
      const position = this.cameraPositions[pos];
      this.animateCamera(
          new THREE.Vector3(position.x, position.y, position.z),
          new THREE.Vector3(position.controlX, position.controlY, position.controlZ)
      );
    },
    // Ürünü yükler ve sahneye ekler
    loadProduct(product) {
      product.loading = true;
      this.products[product._id] = product;

      console.log('load pro', product);
      this.loadModel(this.getModelUrl(product), (gltf, size) => {
        const model = gltf.scene;
        // Model iskeletini değiştirme işlemi şu an devre dışı
        const sceneModel = model;
        this.scene.add(sceneModel);
        // İskelet yardımcısı şu an devre dışı
        
        // Model nesnelerinin gölge ve malzeme özelliklerini ayarla
        sceneModel.traverse(function (object) {
          if (object.isMesh) {
            object.castShadow = true;
            object.receiveShadow = true;
            object.material.envMapIntensity = 0.3;
            // Poligon öteleme ayarları şu an devre dışı
          }
        });

        // Modeli animasyon grubuna ve ürün modellerine ekle
        this.animationGroup.add(model);
        this.productModels[product._id] = model;
        console.log('sizes', size);
        // Ürün için nokta işaretçisi ekle
        this.productDots[product._id] = this.addDot(product, model, size);

        // Yükleme durumunu güncelle
        product.loading = false;
        product.loaded = true;
        this.products[product._id] = product;
      })
    },
    listToggled(value) {
      if (!value && !this.billboardVisible) {
        this.setCamera('face');
      } else if (value) {
        this.setCamera('default');
        if (this.billboardVisible) {
          this.hideBillboard(this.billboard);
        }
      }
    },
    // Sahneden ürünü kaldırır ve ilgili kaynakları temizler
    removeProduct(product) {
      const model = this.productModels[product._id];
      if (model) {
        // Modeli sahneden kaldır
        this.scene.remove(model);
        this.scene.children = this.scene.children.filter(child => child.uuid !== model.uuid);
        console.log('removing model', this.scene.children);

        // Model geometrisi, materyalleri ve dokularını bellekten temizle
        model.traverse((object) => {
          if (object.isMesh) {
            console.log('object', object.isMesh, object)
            object.geometry.dispose();
            object.material.dispose();
            if (object.material.map) object.material.map.dispose();
          }
        });

        // Model referanslarını temizle
        delete this.productModels[product._id];
        this.removeDot(product._id);
        //  delete this.products[product._id];

        // Ürün durumunu güncelle
        product.loading = false;
        product.loaded = false;
        this.products[product._id] = product;
        this.scene.updateMatrixWorld();
        console.log('removed product', model, this.scene);
      }
    },

    // Avatar modelini yükler ve sahneye ekler
    loadBody() {
      this.loadModel(this.current_body, (gltf) => {
        const model = gltf.scene;
        // Model nesnelerinin gölge ve malzeme özelliklerini ayarla
        model.traverse(function (object) {
          if (object.isMesh) {
            object.castShadow = true;
            object.receiveShadow = true;
            object.material.envMapIntensity = 0.3;
          }
        });
        // Modeli sahneye ekle ve kamerayı ayarla
        this.bodyModel = model;
        this.animationGroup.add(model);
        this.hideLogo();
        this.scene.add(model);
        this.setCamera('face');
        this.loadProduct(this.mainProduct);
        //this.$refs.bottomList.openPanel();
      })
    },
    addLogo() {
      // Logo modelini yükle ve sahneye ekle
      const self = this;
      this.loadModel('/M.glb', (gltf) => {
        const model = gltf.scene;
        const clip = self.filterAnimation(gltf.animations[0]);
        const action = self.mixer.clipAction(clip);

        action.play();
        model.scale.set(0, 0, 0);
        model.position.y = 0.5;
        this.loadLogo = model;
        this.scene.add(model);
        this.showLogo();
      })
    },

    showLogo() {
      // Logo modelini göster ve büyüt
      new TWEEN.Tween(this.loadLogo.scale)
          .to({x: 0.25, y: 0.25, z: 0.25}, 1000) // 1 saniye içinde normal boyuta ölçekle
          .easing(TWEEN.Easing.Exponential.Out) // Elastik efekt için üstel yumuşatma
          .start();
      this.billboardVisible = true;
    },

    hideLogo() {
      // Logo modelini gizle ve küçült  
      new TWEEN.Tween(this.loadLogo.scale)
          .to({x: 0, y: 0, z: 0}, 1000) // 1 saniye içinde sıfır boyuta ölçekle
          .easing(TWEEN.Easing.Exponential.In) // Yumuşak kapanma için üstel yumuşatma
          .start();
      this.billboardVisible = false;
    },

    initThree() {
      // Container referansını al
      const container = this.$refs.container;

      // Işın çizgisi için materyal oluştur
      this.rayLineMaterial = markRaw(new THREE.LineBasicMaterial({color: 0xff0000}));

      // Temel Three.js nesnelerini oluştur
      this.clock = markRaw(new THREE.Clock());
      this.mouse = markRaw(new THREE.Vector2());
      this.raycaster = markRaw(new THREE.Raycaster());

      // Kamera ayarları
      this.camera = markRaw(new THREE.PerspectiveCamera(45, container.clientWidth / container.clientHeight, 1, 1000));
      this.camera.lookAt(new THREE.Vector3(-100, 1, 4)); // Modelin merkezine bak, Y değerini gerektiği gibi ayarla
      this.camera.near = 0.1;
      this.camera.far = 1000;
      this.animationGroup = markRaw(new THREE.AnimationObjectGroup());

      // Animasyon karıştırıcısını oluştur
      this.mixer = markRaw(new THREE.AnimationMixer(this.animationGroup));

      // Sahne ayarları
      this.scene = markRaw(new THREE.Scene());
      this.scene.background = new THREE.Color(0xfcfcfc);
      this.scene.fog = new THREE.Fog(0xcccccc, 30, 90);

      // Yarım küre ışığı 1 - Genel aydınlatma için
      const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff); 
      hemiLight.position.set(10, 20, 0);
      this.scene.add(hemiLight);

      // Yarım küre ışığı 2 - Sıcak tonlar için
      const hemiLight2 = new THREE.HemisphereLight(0xffe5cc, 0xffffff);
      hemiLight2.position.set(-10, -20, 0);
      this.scene.add(hemiLight2);

      // Yönlü ışık - Gölgeler için
      const dirLight = new THREE.DirectionalLight(0xffffff);
      dirLight.position.set(-3, 3, 3);
      dirLight.castShadow = true;
      dirLight.shadow.camera.top = 2;
      dirLight.shadow.camera.bottom = -2;
      dirLight.shadow.camera.left = -2;
      dirLight.shadow.camera.right = 2;
      dirLight.shadow.camera.near = 0.1;
      dirLight.shadow.camera.far = 40;
      this.scene.add(dirLight);

      // Spot ışığı - Modelin yüzünü aydınlatmak için
      const spotLight = new THREE.SpotLight(0xffffff, 1);
      spotLight.position.set(0, 2, 2);
      spotLight.target.position.set(0, 0, 0);
      spotLight.angle = Math.PI / 4;
      spotLight.penumbra = 0.5;
      this.scene.add(spotLight);
      this.scene.add(spotLight.target);

      // Yüz ışığı - Yumuşak aydınlatma için
      const faceLight = new THREE.HemisphereLight(0xcccccc, 0xffffff, 0.5);
      faceLight.position.set(0, 5, 5);
      this.scene.add(faceLight);

      // Renderer ayarları
      this.renderer = new THREE.WebGLRenderer({antialias: true});

      // Kontrol ayarları
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.maxPolarAngle = Math.PI / 1.85;
      this.controls.minPolarAngle = 0;
      this.controls.minDistance = 0;
      this.controls.maxDistance = 10;

      // Renderer ek ayarları
      // Cihazın piksel oranına göre renderer çözünürlüğünü ayarla
      this.renderer.setPixelRatio(window.devicePixelRatio);
      
      // Renderer boyutunu container boyutuna göre ayarla
      this.renderer.setSize(container.clientWidth, container.clientHeight);
      
      // Renk uzayını SRGB olarak ayarla - daha doğru renk gösterimi için
      this.renderer.outputColorSpace = THREE.SRGBColorSpace;
      
      // Gölgelendirmeyi etkinleştir
      this.renderer.shadowMap.enabled = true;
      
      // Gölge haritası türünü ayarla - daha yumuşak gölgeler için
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      
      // Ton eşlemeyi etkinleştir - daha gerçekçi ışık efektleri için
      this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 1.0;
      
      // Anti-aliasing kalitesini artır
      this.renderer.antialias = true;

      container.appendChild(this.renderer.domElement);
      this.setCamera('init');

      // Idle animasyonunu yükle
      const self = this;
      this.loadModel("/idle.glb", function (gltf) {
        const clip = self.filterAnimation(gltf.animations[0]);
        const action = self.mixer.clipAction(clip);
        self.walkAction = action;
        self.walkAction.play();
      });

      /* HDR arkaplan için - İhtiyaç duyulduğunda kullanılabilir
      new RGBELoader().load(
        "/sky.hdr",
        (texture) => {
          texture.mapping = THREE.EquirectangularReflectionMapping;
          this.scene.environment = texture;
          this.scene.background = texture;
        }
      );*/

      /* Dokulu zemin için - İhtiyaç duyulduğunda kullanılabilir
      const textureLoader = new THREE.TextureLoader();
      const groundTexture = textureLoader.load('/texture/sand.jpg');
      groundTexture.wrapS = groundTexture.wrapT = THREE.RepeatWrapping;
      groundTexture.repeat.set(8, 8);
      const groundMaterial = new THREE.MeshPhongMaterial({
        map: groundTexture,
        depthWrite: false
      });
      */

      window.addEventListener('resize', this.onWindowResize);

      // Dairesel zemin oluştur
      const groundRadius = 50;
      const groundSegments = 32;
      const groundGeometry = new THREE.CircleGeometry(groundRadius, groundSegments);

      const groundMaterial = new THREE.MeshPhongMaterial({
        color: 0xfefefe,
        depthWrite: false
      });
      const ground = new THREE.Mesh(groundGeometry, groundMaterial);
      ground.rotation.x = -Math.PI / 2;
      ground.receiveShadow = true;
      ground.position.y += 0.045;
      this.scene.add(ground);

      this.animate();
    },
    // Kamera pozisyonunu ekranda göstermek için kullanılan metod
    updateCameraPositionDisplay() {
      const pos = this.camera.position;
      const text = `X: ${pos.x.toFixed(2)}, Y: ${pos.y.toFixed(2)}, Z: ${pos.z.toFixed(2)}`;
      this.$refs.cameraPositionDisplay.textContent = text;
    },
    // Pencere boyutu değiştiğinde kamera ve renderer'ı güncelleyen metod 
    onWindowResize() {
      const container = this.$refs.container;
      this.camera.aspect = container.clientWidth / container.clientHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(container.clientWidth, container.clientHeight);
    },
    // Her frame'de çağrılan animasyon döngüsü metodu
    animate() {
      requestAnimationFrame(this.animate);
      // Animasyon deltaTime'ını hesapla
      const delta = this.clock.getDelta();
      // Karakter animasyonlarını güncelle
      if (this.mixer) {
        this.mixer.update(delta);
      }
      // Kamera kontrollerini güncelle
      this.controls.update();
      //this.updateCameraPositionDisplay();
      // TWEEN animasyonlarını güncelle
      TWEEN.update();
      // Sahneyi render et
      this.renderer.render(this.scene, this.camera);
    },
    // Nesne etrafına bounding box ekleyen metod
    addBoundingBox(object, scene) {
      const bbox = new THREE.Box3().setFromObject(object);
      const bboxHelper = new THREE.Box3Helper(bbox, 0xff0000); // Kırmızı bounding box
      scene.add(bboxHelper);
    },
    // GLB model dosyasını yükleyen metod
    loadModel(url, callback) {
      const loader = new GLTFLoader();
      loader.load(
          url,
          (gltf) => {
            // Modeli al
            const model = gltf.scene;
            // Model için sınırlayıcı kutu oluştur
            const box = new THREE.Box3().setFromObject(model);
            // Modelin boyutlarını hesapla
            const size = new THREE.Vector3();
            box.getSize(size);

            callback(gltf, size);
          },
          undefined,
          (error) => {
            console.error('GLB modeli yüklenirken bir hata oluştu:', error);
          }
      )
      // Gerektiğinde loadAvatar, filterAnimation gibi diğer metodlar eklenebilir
    },
    // Animasyon parçalarını filtreleyen metod
    filterAnimation(animation) {
      animation.tracks = animation.tracks.filter((track) => {
        const name = track.name;
        return name.endsWith("Hips.position") || name.endsWith(".quaternion");
      });
      return animation;
    },
    createBillboard() {
      // Billboard'ın boyutlarını tanımla
      const width = 1;  // Genişliği gerektiği gibi ayarla
      const height = 2; // Yüksekliği gerektiği gibi ayarla
      const borderThickness = 0.05; // Üst ve alt kenarlık kalınlığı
      const sideBorderThickness = 0.05; // Yan kenarlıklar için daha kalın kenarlık

      // Billboard geometrisini oluştur
      const billboardGeometry = new THREE.BoxGeometry(width, height, borderThickness);

      // Billboard için materyaller oluştur
      const billboardMaterial = new THREE.MeshBasicMaterial({color: 0xeeeeee}); // Yumuşatılmış beyaz panel
      const borderMaterial = new THREE.MeshBasicMaterial({color: 0x222222}); // Yumuşatılmış siyah kenarlık

      // Billboard mesh'ini oluştur
      const billboard = new THREE.Mesh(billboardGeometry, billboardMaterial);

      // Kenarlıkları oluştur (yanlarda biraz daha büyük ve kalın)
      const borderGeometry = new THREE.BoxGeometry(width + 2 * sideBorderThickness, height + 2 * borderThickness, borderThickness);
      const border = new THREE.Mesh(borderGeometry, borderMaterial);
      border.position.setZ(-borderThickness / 2); // Billboard'ın arkasıyla hizalamak için ayarla

      // Billboard ve kenarlığı grupla
      const billboardGroup = new THREE.Group();
      billboardGroup.add(billboard);
      billboardGroup.add(border);

      // Billboard dokusu için canvas ve context oluştur
      this.billboardCanvas = document.createElement('canvas');
      this.billboardCanvas.width = 256; // Gerektiği gibi ayarla
      this.billboardCanvas.height = 512; // Gerektiği gibi ayarla
      this.billboardContext = this.billboardCanvas.getContext('2d');

      // Başlangıç dokusu oluştur (daha sonra güncellenebilir)
      const billboardTexture = new THREE.CanvasTexture(this.billboardCanvas);
      billboardMaterial.map = billboardTexture;
      // Sahnede sabit bir konuma ayarla
      // Sağa (x) ve geriye (z) 2 birim hareket ettir
      billboardGroup.position.set(1.1, 1.1, -0.5); // x, y, z koordinatlarını gerektiği gibi ayarla

      // Grubu y-ekseni etrafında 45 derece döndür
      billboardGroup.rotation.y = -Math.PI / 8; // Radyan cinsinden 45 derece

      billboardGroup.scale.set(0, 0, 0);
      this.billboardVisible = false;
      // Billboard grubunu sahneye ekle
      this.scene.add(billboardGroup);
      billboardGroup.traverse(function (object) {
        if (object.isMesh) {
          object.castShadow = true;
          object.receiveShadow = true;
          object.material.envMapIntensity = 0.3;
        }
      });
      return billboardGroup;
    },
    // URL'den doku yüklemek için Promise döndüren yardımcı fonksiyon
    loadTexture(url) {
      return new Promise((resolve, reject) => {
        new THREE.TextureLoader().load(url, resolve, undefined, reject);
      });
    },
    // Kaynak boyutlarını en-boy oranını koruyarak hedef boyutlara sığdırmak için hesaplama yapar
    calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
      const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
      return {width: srcWidth * ratio, height: srcHeight * ratio};
    },
    /**
     * Billboard dokusunu ürün bilgileriyle günceller
     * @param {Object} product - Gösterilecek ürün bilgisi
     * 
     * 1. Canvas temizlenir ve beyaz arka plan ayarlanır
     * 2. Ürün görseli yüklenir:
     *    - Birden fazla görsel varsa ikinci görsel kullanılır
     *    - Tek görsel varsa o kullanılır
     * 3. Görsel en-boy oranı korunarak canvas'a yerleştirilir:
     *    - Görsel boyutları hesaplanır
     *    - Canvas'ın ortasına hizalanır
     * 4. Ürün detayları metin olarak eklenir:
     *    - Ürün adı kalın fontla yazılır
     *    - Açıklama daha ince fontla yazılır
     *    - Metinler satırlara bölünür
     * 5. Billboard dokusu güncellenir
     */
    async updateBillboardTexture(product) {
      console.log('product texture', product)
      // Canvas'ı temizle
      this.billboardContext.clearRect(0, 0, this.billboardCanvas.width, this.billboardCanvas.height);
      // Canvas arka planını beyaz yap
      this.billboardContext.fillStyle = '#FFFFFF'; // Beyaz renk
      this.billboardContext.fillRect(0, 0, this.billboardCanvas.width, this.billboardCanvas.height); // Canvas'ı beyazla doldur

      // Yeni ürün görselini yükle
      let img;
      if (product.images.length > 1) {
        img = product.images[1];
      } else {
        img = product.images[0];
      }
      let texture;
      try {
        texture = await this.loadTexture(img);
      } catch (error) {
        console.error('Error loading texture:', error);
        // Hata durumunda işle (örn. varsayılan doku ayarla)
      }
      if (texture) {
        const imgWidth = texture.image.width;
        const imgHeight = texture.image.height;

        // En-boy oranını koruyarak en uygun boyutları hesapla
        const {
          width,
          height
        } = this.calculateAspectRatioFit(imgWidth, imgHeight, this.billboardCanvas.width - 20, this.billboardCanvas.height - 240);

        // Görseli canvas'ın ortasına yerleştir
        const x = (this.billboardCanvas.width - width) / 2;
        const y = (this.billboardCanvas.height - 240 - height) / 2 + 10;

        this.billboardContext.drawImage(texture.image, x, y, width, height);
      }
      // Yeni görseli canvas'a çiz
      // Yeni ürün detaylarını metin olarak ekle
      this.billboardContext.fillStyle = '#000000';
      this.billboardContext.font = '13px Poppins';
      let yPos = this.billboardCanvas.height - 210;
      const nameChunks = this.splitTextIntoLines(product.name, 36);
      nameChunks.forEach(chunk => {
        this.billboardContext.fillText(chunk, 10, yPos);
        yPos += 15; // Y pozisyonunu sonraki satır için artır; font boyutuna göre ayarla
      });
      const descriptionChunks = this.splitTextIntoLines(product.description, 36);

      // Açıklama için fontu ayarla
      this.billboardContext.font = 'ExtraLight 8px Poppins'; // Font boyutunu gerektiği gibi ayarla

      this.billboardContext.fillStyle = '#333333';
      // Açıklamanın her parçasını render et
      descriptionChunks.forEach(chunk => {
        this.billboardContext.fillText(chunk, 10, yPos);
        yPos += 15; // Y pozisyonunu sonraki satır için artır; font boyutuna göre ayarla
      });

      yPos += 10;
      this.billboardContext.font = 'Bold 15px Poppins';
      //this.billboardContext.fillText(`${product.price} TL`, 10, yPos);

      // Dokuyu güncelle
      this.billboard.children[0].material.map.needsUpdate = true;
    },
    /**
     * Metni belirli bir maksimum uzunluğa göre satırlara böler
     */
    splitTextIntoLines(text, maxLineLength) {
      const words = text.split(' ');
      const lines = [];
      let currentLine = words[0];

      for (let i = 1; i < words.length; i++) {
        if (currentLine.length + words[i].length + 1 <= maxLineLength) {
          currentLine += ' ' + words[i];
        } else {
          lines.push(currentLine);
          currentLine = words[i];
        }
      }
      lines.push(currentLine); // Son satırı ekle

      return lines;
    },

    /**
     * Billboard grubunu gösterir ve animasyonla büyütür
     */
    showBillboard(billboardGroup) {
      new TWEEN.Tween(billboardGroup.scale)
          .to({x: 1, y: 1, z: 1}, 1000) // 1 saniye içinde normal boyuta ölçekle
          .easing(TWEEN.Easing.Exponential.Out) // Elastik efekt için üstel yumuşatma
          .start();
      this.billboardVisible = true;
    },

    /**
     * Billboard grubunu gizler ve animasyonla küçültür
     */
    hideBillboard(billboardGroup) {
      new TWEEN.Tween(billboardGroup.scale)
          .to({x: 0, y: 0, z: 0}, 1000) // 1 saniye içinde sıfır boyuta ölçekle
          .easing(TWEEN.Easing.Exponential.In) // Yumuşak kapanma için üstel yumuşatma
          .start();
      this.billboardVisible = false;
    },
    /**
     * Noktaya tıklandığında çağrılan fonksiyon
     * 
     * 1. Tıklanan noktaya ait billboard'u kontrol eder
     * 2. Billboard görünür değilse gösterir
     * 3. Alt paneli kapatır
     * 4. Kamerayı panel görünümüne ayarlar
     */
    handleClickOnDot() {
      if (!this.billboardVisible) {
        this.showBillboard(this.billboard);
      }

      this.$refs.bottomList.closePanel();
      this.setCamera('panel');
    },

    /**
     * Fare imleci nokta üzerine geldiğinde çağrılan fonksiyon
     * 
     * 1. Nokta ile ilişkili ürün bilgilerini alır
     * 2. Billboard dokusunu günceller
     * 3. Noktaya tıklama işlemini tetikler
     */
    handleHover(obj) {
      this.updateBillboardTexture(obj.userData.product);
      this.handleClickOnDot();
    },

    /**
     * Fare hareketi sırasında çağrılan fonksiyon
     * 
     * 1. Fare pozisyonunu normalize edilmiş koordinatlara dönüştürür (-1 ile +1 arası)
     * 2. Işın izleme (raycasting) için kamera ve fare pozisyonunu günceller
     * 3. Noktalarla kesişimleri kontrol eder
     * 4. Kesişim varsa ve nokta bir ürünle ilişkiliyse hover işlemini tetikler
     */
    onMouseMove(event) {
      const rect = this.$refs.container.getBoundingClientRect();
      this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      this.raycaster.setFromCamera(this.mouse, this.camera);

      const dots = Object.values(this.productDots);
      const intersects = this.raycaster.intersectObjects(dots);

      if (intersects.length > 0 && intersects[0].object.userData.product) {
        this.handleHover(intersects[0].object);
      }
    },
    /**
     * Ürün için etkileşimli nokta ekler
     * @param {Object} product - Eklenecek ürün bilgisi
     * @param {Object} model - 3D model referansı 
     * @param {Object} size - Model boyut bilgisi
     * @returns {THREE.Mesh} Oluşturulan nokta mesh'i
     * 
     * 1. Küre geometrisi ve materyal oluşturulur
     * 2. Küre mesh'i oluşturulur ve ürün bilgisi eklenir
     * 3. Ürünün vücut bölgesine göre nokta konumu ayarlanır:
     *    - Üst beden: Göğüs hizası
     *    - Alt beden: Bel hizası
     *    - Kafa: Baş hizası
     *    - Ayak/Ayakkabı: Zemin hizası
     *    - El: Kol hizası
     *    - Tam vücut: Orta nokta
     * 4. Nokta sahneye eklenir ve döndürülür
     */
    addDot(product, model, size) {
      console.log('model size', size, product.body_part)
      const sphereGeometry = new THREE.SphereGeometry(0.04, 32, 32); // Küre yarıçapı ve segment sayısı
      const sphereMaterial = new THREE.MeshBasicMaterial({color: 0x333333});
      const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
      sphere.userData.product = product;

      switch (product.body_part.trim()) {
        case 'üst beden': // Üst beden
          console.log('üst bed');
          sphere.position.set(.33, 1.45, 0);
          break;
        case 'alt beden': // Alt beden
          sphere.position.set(.33, .8, 0);
          break;
        case 'kafa': // Baş
          sphere.position.set(.33, 1.55, 0);
          break;
        case 'ayak':
        case 'ayakkabı': // Ayak
          sphere.position.set(.33, 0, 0);
          break;
        case 'el': // El
          sphere.position.set(.33, 1, 0);
          break;
        case 'full body': // Tam vücut
          sphere.position.set(.33, 1, 0);
          break;
        default:
          sphere.position.set(.33, -1, 0);
          break;
      }

      this.scene.add(sphere);
      //this.addBoundingBox(sphere, this.scene);
      return sphere;
    },
    /**
     * Belirtilen ürün ID'sine ait noktayı sahneden kaldırır
     * @param {string} productId - Kaldırılacak ürünün ID'si
     * 
     * 1. productId ile ilişkili nokta (küre) alınır
     * 2. Nokta mevcutsa:
     *    - Nokta sahneden kaldırılır
     *    - Sahne çocukları filtrelenerek nokta çıkarılır
     *    - Geometri ve materyal bellekten temizlenir
     *    - productDots nesnesinden referans kaldırılır
     *    - Sahne matrisi güncellenir
     * 3. Nokta bulunamazsa konsola hata mesajı yazılır
     */
    removeDot(productId) {
      const dot = this.productDots[productId];
      console.log('dot', dot);
      
      if (dot) {
        this.scene.remove(dot);
        this.scene.children = this.scene.children.filter(child => child.uuid !== dot.uuid);
        dot.geometry.dispose();
        dot.material.dispose();

        delete this.productDots[productId];

        this.scene.updateMatrixWorld()
      } else {
        console.log('Dot not found for product ID:', productId);
      }
    }
  },
  computed: {
    /**
     * Mevcut vücut modelini hesaplar ve döndürür
     * 
     * 1. Ana ürünün cinsiyetine göre 'male' veya 'female' değeri belirlenir
     * 2. Eğer kullanıcının bir avatar modeli varsa ve cinsiyet uyuşuyorsa:
     *    - Kullanıcının avatar modelini döndürür
     * 3. Aksi takdirde:
     *    - Cinsiyete göre varsayılan erkek veya kadın modelini döndürür
     * 
     * @returns {string} Avatar model dosyasının yolu
     */
    current_body() {
      const gender = this.mainProduct.gender === 'erkek' ? 'male' : 'female'
      console.log('gender', gender);
      if (this.body && (gender === this.avatarGender)) {
        return this.body
      } else {
        return this.mainProduct.gender === 'erkek' ? '/models/male.glb' : '/models/female.glb'
      }
    }
  }
};
</script>
