<template>
    <!-- Ana kapsayıcı -->
    <div class="final-wrapper">
        <!-- Kamera görünümü - 3'ten az fotoğraf çekilmişse gösterilir -->
        <div class="camera-container" v-if="capturedImages.length < 3">
            <!-- Yüz pozisyonu talimatı -->
            <div class="message">{{ faceTexts[capturedImages.length] }}</div>
            <!-- Kamera alanı -->
            <div class="camera">
                <!-- Yüz pozisyonu önizleme - son fotoğrafta ters çevrilir -->
                <div class="preview" :class="[capturedImages.length === 2 ? 'reverse' : '']">
                    <img :src="faceSides[capturedImages.length]" />
                </div>
                <!-- Fotoğraf çekim efekti -->
                <div class="capture-effect" v-show="showCaptureEffect" @animationend="showCaptureEffect = false"></div>
                <!-- Kamera video akışı -->
                <video ref="videoElement" class="camera-feed" autoplay playsinline></video>
                <!-- Fotoğraf yakalama için gizli canvas -->
                <canvas ref="canvas" style="display: none;"></canvas>
            </div>
            <!-- Kamera kontrol butonları -->
            <div class="camera-controls">
                <!-- Kamera değiştirme butonu -->
                <div class="btn-icon" @click="switchCamera">
                    <img src="/form/switch-cam.png" />
                </div>
                <!-- Fotoğraf çekme butonu -->
                <div class="btn-icon cam" @click="captureImage">
                    <div class="innercircle"></div>
                </div>
                <!-- Bilgi butonu -->
                <div class="btn-icon" @click="showInfo">
                    <img src="/form/info.png" />
                </div>
            </div>
        </div>
        <!-- Yükleme görünümü - 3 fotoğraf çekildikten sonra gösterilir -->
        <div class="upload-container" v-else>
            <!-- Başlık -->
            <div class="title">Nasıl görünüyor?</div>
            <!-- Çekilen fotoğrafların önizlemesi -->
            <div class="image-container">
                <!-- Sol kolon - ön yüz fotoğrafı -->
                <div class="col-left">
                    <div class="image-taken" :style="`background-image: url(${capturedImages[0].url})`">
                        <div class="preview">
                            <img :src="faceSides[0]" />
                        </div>
                    </div>
                </div>
                <!-- Sağ kolon - yan yüz fotoğrafları -->
                <div class="col-right">
                    <div class="image-taken" :style="`background-image: url(${capturedImages[1].url})`">
                        <div class="preview">
                            <img :src="faceSides[1]" />
                        </div>
                    </div>
                    <div class="image-taken" :style="`background-image: url(${capturedImages[2].url})`">
                        <div class="preview reverse">
                            <img :src="faceSides[2]" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- Bilgi mesajı -->
            <div class="info">
                <div class="info-icon">
                    <img src="/form/info.png" />
                </div>
                <div class="info-text">
                    Lütfen fotoğraflarınızın kılavuzumuza uygun olduğundan emin olun.
                </div>
            </div>
            <!-- İşlem butonları -->
            <div class="buttons">
                <!-- Yeniden çekim butonu -->
                <div class="retake" @click="retake">
                    <img src="/refresh_white.png" />
                    <span>Yeniden Çek</span>
                </div>
                <!-- Yükleme butonu -->
                <div class="upload" @click="createNewAvatar">Yükle</div>
            </div>
            <!-- Yükleme durumu overlay'i -->
            <div class="uploading-overlay" v-if="uploading">
                <div class="uploading-wrapper">
                    <!-- Yükleme animasyonu -->
                    <div class="loader" style="background-image: url('/form/border.png');">
                        <div class="rotator rotating-element" style="background-image: url('/form/loading.png');"></div>
                        <div class="face" style="background-image: url('/form/face.png');"></div>
                        <div class="percentage">{{uploadProgress}} %</div>
                    </div>
                    <!-- Yükleme durumu metinleri -->
                    <div class="title">Yükleniyor</div>
                    <div class="desc">Fotoğraflarınız yükleniyor</div>
                    <!-- Logo -->
                    <div class="logo"><img src="/logo-horizontal.png" /></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        info: Function, // Bilgi gösterme fonksiyonu
        uid: String, // Kullanıcı ID'si
        completed: Function, // Tamamlanma fonksiyonu 
        gender: String // Cinsiyet
    },
    data() {
        return {
            token: process.env.VUE_APP_API_TOKEN, // API erişim anahtarı
            stream: null, // Kamera akışı
            currentDeviceId: null, // Mevcut kamera cihaz ID'si
            allVideoInputs: [], // Tüm video giriş cihazları
            capturedImages: [], // Yakalanan görüntüler dizisi
            faceSideIndex: 0, // Yüz tarafı indeksi
            uploadProgress: 0, // Yükleme ilerleme durumu
            avatarId: null, // Avatar ID'si
            uploading: false, // Yükleme durumu
            showCaptureEffect: false, // Yakalama efekti gösterimi
            faceTexts: ['Lütfen doğrudan kameraya bakın', 'Kafanızı sağa çevirin.', 'Kafanızı sola çevirin.'], // Yüz yönlendirme metinleri
            faceSides: ['/form/face-front.png', '/form/face-left.png', '/form/face-right.png'] // Yüz yönlendirme görselleri
        };
    },
    mounted() {
        this.getCameraFeed(); // Bileşen yüklendiğinde kamera akışını başlat
    },
    methods: {
        retake() {
            this.capturedImages = [];
            this.getCameraFeed(); // Fotoğrafları yeniden çek
        },
        async getCameraFeed() {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                this.allVideoInputs = devices.filter(device => device.kind === 'videoinput');

                if (this.allVideoInputs.length > 0) {
                    this.currentDeviceId = this.allVideoInputs[0].deviceId;

                    this.stream = await navigator.mediaDevices.getUserMedia({
                        video: { deviceId: this.currentDeviceId }
                    });
                    this.$refs.videoElement.srcObject = this.stream;
                    this.$refs.videoElement.style.transform = 'scaleX(-1)';
                } else {
                    alert('Kamera bulunamadı.');
                }
            } catch (err) {
                alert('Kamera erişimi reddedildi.');
                console.error(err);
            }
        },

        async createNewAvatar() {
            // Yeni avatar oluşturma işlemi
            this.stopCamera();
            this.uploading = true;
            try {
                const response = await fetch(`${process.env.VUE_APP_AVATURN_URL}/avatars/new`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        user_id: this.uid
                    })
                });

                if (!response.ok) {
                    this.isLoading = false;
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                console.log('new avatar', data.upload_url);
                this.avatarId = data.avatar_id;
                this.uploadAvatarFiles(data.upload_url);
            } catch (error) {
                console.error('API çağrısında hata:', error);

                this.uploading = false;
            }
        },
        uploadAvatarFiles(uploadUrl) {
            // Avatar dosyalarını yükleme işlemi
            const self = this;
            const formData = new FormData();
            formData.append('body-type', this.gender);
            formData.append('telephoto', 'false');
            formData.append('image-frontal', this.capturedImages[0].blob, 'image-frontal.jpg');
            formData.append('image-side-1', this.capturedImages[1].blob, 'image-side-1.jpg');
            formData.append('image-side-2', this.capturedImages[2].blob, 'image-side-2.jpg');
            const xhr = new XMLHttpRequest();
            xhr.open('POST', uploadUrl);

            xhr.upload.onprogress = function (event) {
                if (event.lengthComputable) {
                    self.uploadProgress = parseInt((event.loaded / event.total) * 100);
                    console.log(`Yükleme ilerlemesi: ${self.uploadProgress}%`);
                }
            };

            xhr.onload = function () {
                if (xhr.status === 200) {
                    self.uploadProgress = 0;
                    console.log('Yükleme başarılı:', xhr.responseText);
                    self.uploading = false;
                    self.completed(self.avatarId);
                } else {
                    console.error('Yükleme başarısız:', xhr.statusText);
                    self.uploading = false;
                }
            };

            xhr.onerror = function () {
                console.error('Ağ hatası');
                self.uploading = false;
            };

            xhr.send(formData);

        },
        captureImage() {
            // Görüntü yakalama işlemi
            this.showCaptureEffect = true;
            const videoElement = this.$refs.videoElement;
            const canvas = this.$refs.canvas;
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;

            const context = canvas.getContext('2d');
            context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

            canvas.toBlob(blob => {
                this.capturedImages.push({
                    url: URL.createObjectURL(blob),
                    blob: blob
                });

                console.log('Yakalanan görüntü sayısı:', this.capturedImages);
            }, 'image/png');
        },

        switchCamera() {
            // Kamera değiştirme işlemi
            const currentIndex = this.allVideoInputs.findIndex(device => device.deviceId === this.currentDeviceId);
            if (currentIndex !== -1 && this.allVideoInputs.length > 1) {
                const nextIndex = (currentIndex + 1) % this.allVideoInputs.length;
                this.currentDeviceId = this.allVideoInputs[nextIndex].deviceId;
                this.getCameraFeed();
            }
        },
        showInfo() {
            // Bilgi gösterme işlemi
            this.info();
        },
        stopCamera() {
            // Kamerayı durdurma işlemi
            if (this.stream) {
                this.stream.getTracks().forEach(track => track.stop());
                this.stream = null;
            }
        },
    },
    beforeUnmount() {
        this.stopCamera(); // Bileşen kaldırılmadan önce kamerayı durdur
    },
};
</script>

<style scoped>
/* Yükleme yüzdesi */
.percentage {
    position: absolute;
    bottom: -20px;
    z-index: 2;
    color: white;
    left: 50%;
    transform: translateX(-50%);
    font-size: 25px;
}

/* Dönen animasyon ve yüz görseli */
.rotator,
.face {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

/* Döndürme animasyonu */
@keyframes rotateAnimation {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

/* Dönen eleman */
.rotating-element {
    animation: rotateAnimation 2s linear infinite;
}

/* Yüz görseli */
.face {
    width: 50px;
    height: 50px;
}

/* Dönen gösterge */
.rotator {
    width: 110px;
    height: 110px;
}

/* Yükleme göstergesi */
.loader {
    width: 250px;
    height: 250px;
    background-size: contain;
    background-position: center;
    position: relative;
}

/* Yükleme kapsayıcısı */
.uploading-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Yükleme overlay'i */
.uploading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20%;
    background: #333;
    z-index: 4;
}

/* Yükleme konteyneri */
.upload-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Yeniden çekme butonu */
.retake {
    color: white;
    max-width: 50%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

/* Yeniden çekme butonu görseli */
.retake img {
    width: 12px;
    height: 12px;
}

/* Yükleme butonu */
.upload {
    color: #333;
    background: white;
    border-radius: 32px;
    width: 50%;
    margin: 0 auto;
    padding: 10px 20px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

/* Butonlar konteyneri */
.buttons {
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 20px;
}

/* Görüntü konteyneri */
.image-container {
    display: flex;
    height: -moz-fit-content;
    height: fit-content;
    height: 370px;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    width: calc(100% - 80px);
}

/* Başlık */
.title {
    text-align: center;
    font-size: 30px;
    color: white;
    padding: 20px 0;
}

/* Açıklama */
.desc {
    font-size: 14px;
    color: white;
}

/* Sol kolon */
.col-left {
    display: flex;
    margin-right: 16px;
    width: 65%;
}

/* Sağ kolon */
.col-right {
    display: flex;
    flex-flow: column;
    gap: 16px;
    width: 35%;
}

/* Bilgi alanı */
.info {
    display: flex;
    gap: 10px;
    max-width: 40%;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
}

/* Bilgi ikonu */
.info-icon {
    padding-right: 10px;
    border-right: 1px solid #ccc;
}

/* Bilgi ikonu görseli */
.info-icon img {
    width: 100%;
}

/* Bilgi metni */
.info-text {
    color: white;
}

/* Çekilen görüntü */
.image-taken {
    align-items: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    display: flex;
    height: 100%;
    justify-content: center;
    -o-object-fit: cover;
    object-fit: cover;
    position: relative;
    width: 100%;
}

/* Önizleme */
.preview {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 15px;
    overflow: hidden;
    max-width: 50px;
    z-index: 2;
}

/* Ters çevrilmiş önizleme */
.preview.reverse {
    transform: rotateY(180deg);
}

/* Önizleme görseli */
.preview img {
    width: 100%;
}

/* Çekilen görüntüdeki önizleme */
.image-taken .preview {
    max-width: 30px;
    border-radius: 4px;
}

/* Mesaj */
.message {
    position: absolute;
    top: 10px;
    left: 20px;
    right: 20px;
    color: white;
    font-size: 14px;
    text-align: center;
}

/* Kamera konteyneri */
.camera-container,
.upload-container,
.final-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Kamera */
.camera {
    flex: 1;
    margin: 15% 10% 10px 10%;
    border-radius: 25px;
    overflow: hidden;
    max-height: 500px;
    position: relative;
}

/* Kamera görüntüsü */
.camera-feed {
    object-fit: cover;
    height: 100%;
    width: 100%;
    transform: scaleX(1);
}

/* Kamera kontrolleri */
.camera-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
}

/* Yakalama efekti */
.capture-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0;
    animation: flash 0.5s forwards;
    z-index: 3;
}

/* Flaş animasyonu */
@keyframes flash {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* Buton ikonu */
.btn-icon img {
    max-width: 100%;
}

/* Buton ikonu konteyneri */
.btn-icon {
    width: 32px;
    height: 32px;
    margin: 20px;
}

/* Logo görseli */
.logo img {
    max-width: 90px;
}

/* Logo konteyneri */
.logo {
    position: absolute;
    bottom: 10px;
}

/* Kamera butonu */
.btn-icon.cam {
    width: 80px;
    height: 80px;
    border: 4px solid white;
    border-radius: 50%;
}

/* İç daire */
.innercircle {
    margin: 5px;
    background: white;
    border-radius: 50%;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
}

/* Mobil görünüm */
@media only screen and (max-width: 450px) {
    .info {
        max-width: 70%;
    }

    .info-icon {
        width: 50px;
    }

    .buttons>div {
        width: 70%;
        max-width: 70%;
    }
}
</style>
