<template>
  <div class="scene-wrapper">
    <!-- Ana ürün varsa görüntüleyici sarmalayıcıyı göster -->
    <div class="viewer-wrapper" v-if="mainProduct">
      <!-- SceneViewer bileşeni, ana ürün ve kullanıcı avatarı bilgileri ile
           3D sahne görüntüleme ve etkileşimli ürün seçimi için kullanılır.
           Aşağıdaki özellikler ile SceneViewer bileşeni yapılandırılır:
           - productlist: Tüm ürünlerin listesi
           - product: Ana ürün bilgisi
           - body: Kullanıcı avatarının URL'si
           - body-type: Kullanıcı vücut tipi, varsayılan olarak 'body_1'
           - avatar-gender: Avatarın cinsiyeti
           - create-avatar: Avatar oluşturma fonksiyonu
           - slug: Ürün veya sahneye özgü tanımlayıcı
           - v-if: Bileşenin yalnızca 'scene' görünüm modunda ve ana ürün mevcutsa gösterilmesini sağlar
      -->
      <SceneViewer
        :productlist="products" 
        :product="mainProduct" 
        :body="userAvatarUrl" 
        :body-type="!userBodyId ? 'body_1': userBodyId" 
        :avatar-gender="avatarGender" 
        :create-avatar="setViewMode"
        v-if="viewMode === 'scene' && mainProduct"
        :slug="slug" 
      />
      <!-- AvaturnViewer bileşeni, 'edit' görünüm modunda ve avaturnUrl varsa -->
      <AvaturnViewer 
        :reset="resetConnection" 
        :avatar-loaded="avatarLoaded" 
        :go-back="setViewMode"
        v-if="viewMode === 'edit' && avaturnUrl" 
        :url="avaturnUrl" 
      />
      <!-- AvatarList bileşeni, 'list' görünüm modunda ve logger varsa -->
      <AvatarList
      v-if="viewMode === 'list' && logger"  
      :avatars="logger.avatars" 
      :back="setViewMode"
      :base-view="viewWithBaseModel"
      :avatar-selected="avatarSelected"
      :delete-avatar="deleteAvatar"
      />
      <!-- CreateAvatar bileşeni, 'create' görünüm modunda -->
      <CreateAvatar 
        v-if="viewMode === 'create'"
        :go-back="setViewMode"
      />
      <!-- CreateAvatarForm bileşeni, 'init' görünüm modunda -->
      <CreateAvatarForm
      :completed="avatarCreated"
      :go-back="setViewMode"
      :uid="userId"
        v-if="viewMode === 'init'"
      />
      <!-- Yükleniyor göstergesi, isLoading true olduğunda -->
      <div v-if="isLoading" class="spinner-wrapper2">
        <div class="spinner2"></div>
        <MatchyLogo3 />
      </div>
    </div>
  </div>
</template>

<script>

import CreateAvatarForm from "@/components/CreateAvatarForm.vue";
import CreateAvatar from "./components/CreateAvatar.vue";

import AvaturnViewer from "@/components/AvaturnViewer.vue";
import AvatarList from "@/components/AvatarList.vue";
import MatchyLogo3 from "@/components/MatchyLogo3.vue";

// Eğer giyilebilir 3D objeleri mediapipe ile test etmek istiyorsanız,
// aşağıdaki import'u aktif edip SceneViewer yerine PoseAvatarComponent'i kullanabilirsiniz.
// PoseAvatarComponent, SceneViewer ile aynı props'ları alır.

import SceneViewer from "@/components/SceneViewer.vue";

export default {
  name: 'App',
  components: {
    SceneViewer,
    CreateAvatarForm,
    CreateAvatar,
    AvaturnViewer,
    AvatarList,
    MatchyLogo3
  },
  data() {
    return {
      viewMode: 'scene', // görünüm modu: sahne, düzenleme, liste, oluşturma, başlangıç
      slug: '', // URL'den alınan benzersiz tanımlayıcı
      waitForRender: false, // render işleminin bekleme durumu
      store_slug: null, // mağaza benzersiz tanımlayıcısı
      logger: null, // günlük kaydı
      fetch: false, // veri çekme durumu
      showScene: false, // sahne görünürlüğü
      showAvaturn: false, // Avaturn görünürlüğü
      isLoading: false, // yükleme durumu
      showAvatars: true, // avatar listesi görünürlüğü
      showLogo: false, // logo görünürlüğü
      userAvatars: [], // kullanıcı avatarları dizisi
      mainProduct: null, // ana ürün
      products: [], // ürünler dizisi
      token: process.env.VUE_APP_API_TOKEN // API erişim anahtarı
    }
  },
  async mounted() {
    // URL'den slug parametresini al
    this.slug = this.getSlug();
    // Ürün verilerini getir
    await this.fetchData();
    // Kullanıcı ID'si yoksa yeni kullanıcı oluştur
    if (this.userId === null) {
      await this.createNewUser();
    } else {
      // Mevcut kullanıcı için günlük kayıtlarını ve avatarları getir
      await this.getLogger(this.userId);
      await this.getUserAvatars();
      // Oturum bilgilerini konsola yazdır
      console.log('session', this.sessionId, this.userAvatarUrl, this.avatarGender, this.userBodyId);      
    }
  },
  computed: {
    avaturnUrl: {
      get() {
        // Vuex store veya localStorage'dan avaturn URL'sini al
        return this.$store.state.avaturnUrl || localStorage.getItem('avaturnUrl');
      },
      set(value) {
        // Avaturn URL'sini Vuex store'a kaydet
        this.$store.dispatch('setAvaturnUrl', value);
      }
    },
    userId: {
      get() {
        console.log('get uid', this.$store.state.userId, localStorage.getItem('userId'));
        // Vuex store veya localStorage'dan kullanıcı ID'sini al
        return this.$store.state.userId || localStorage.getItem('userId');
      },
      set(value) {
        // Kullanıcı ID'sini Vuex store'a kaydet
        this.$store.dispatch('setUserId', value);
      }
    },
    sessionId: {
      get() {
        // Vuex store veya localStorage'dan oturum ID'sini al
        return this.$store.state.sessionId || localStorage.getItem('sessionId');
      },
      set(value) {
        // Oturum ID'sini Vuex store'a kaydet
        this.$store.dispatch('setSessionId', value);
      }
    },
    userAvatarUrl: {
      get() {
        // Vuex store veya localStorage'dan kullanıcı avatar URL'sini al
        return this.$store.state.userAvatarUrl || localStorage.getItem('userAvatarUrl');
      },
      set(value) {
        // Kullanıcı avatar URL'sini Vuex store'a kaydet
        this.$store.dispatch('setUserAvatarUrl', value);
      }
    },
    userBodyId: {
      get() {
        // Vuex store veya localStorage'dan kullanıcı vücut ID'sini al
        return this.$store.state.userBodyId || localStorage.getItem('userBodyId')
      },
      set(value) {
        // Kullanıcı vücut ID'sini Vuex store'a kaydet
        this.$store.dispatch('setUserBodyId', value);
      }
    },
    avatarGender: {
      get() {
        // Vuex store veya localStorage'dan avatar cinsiyetini al
        return this.$store.state.avatarGender || localStorage.getItem('avatarGender')
      },
      set(value) {
        // Avatar cinsiyetini Vuex store'a kaydet
        this.$store.dispatch('setAvatarGender', value);
      }
    },
  },
  watch: {
    // Logger değişkenini izle
    logger: {
      deep: true,
      handler() {
        //console.log('logs', this.logger)
      }
    },
    // Görünüm modunu izle
    viewMode() {
      console.log('viewMode', this.viewMode);
      if (this.viewMode === 'list') {
        this.getUserAvatars();
      }
    }
  },
  methods: {
    // Avatar oluşturulduğunda yeni oturum oluştur
    async avatarCreated(avatar_id) {
      await this.createNewSession(this.userId, avatar_id);
    },
    // Temel model ile görüntüleme
    viewWithBaseModel() {
      this.userAvatarUrl = null;
      this.userBodyId = 'body_1';
      this.avatarGender = null; 
      this.viewMode = 'scene';
    },
    // Görünüm modunu ayarla
    setViewMode(mode) {
      if (mode === 'init' && this.userAvatars.length > 2) {
        console.log('not allowed')
      } else {
        this.viewMode = mode;
      }
    },
    // Avatar seçildiğinde yeni oturum oluştur
    async avatarSelected(avatar) {
        const avatar_id = avatar.id;
        await this.createNewSession(this.userId, avatar_id);
    },
    // Bağlantıyı sıfırla
    async resetConnection () {
      //localStorage.clear();

      if (this.userId === null) {
        await this.createNewUser();
      } else {
        await this.getUserAvatars();
        console.log('session', this.sessionId, this.userAvatarUrl, this.avatarGender, this.userBodyId);
        this.viewMode = 'list';
      }
    },
    // Ürün verilerini getir
    async fetchData () {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/store/plugin?product=${this.slug}`);
        const data = await response.json();
        console.log('fetched', data)
        this.mainProduct = data.data.selected_product;
        data.data.products.map(product => {
          this.products[product._id] = product;
        })
        this.products[this.mainProduct._id] = this.mainProduct;
        this.store_slug = this.mainProduct.brand.slug;
        window.parent.postMessage('content-slid-out', '*');
        console.log('response', data, this.mainProduct, this.products);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    // Avatar yüklendiğinde bilgileri kaydet
    avatarLoaded(res) {
      this.userAvatarUrl = res.url;
      this.userBodyId = res.bodyId;
      this.avatarGender = res.gender;
      this.viewMode = 'scene';
    },
    // Yeni log kaydı oluştur
    createLog(action, log_txt) {
      const log = {
        action, 
        log: log_txt,
        store: this.store_slug
      }
      this.logger.logs.push(log);
    },
    // Kullanıcının logger kayıtlarını getir
    async getLogger(user_id) {
      const response = await fetch(`${process.env.VUE_APP_API_URL}/plugin-logs/${user_id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log('get logger', response.ok)
        // Eğer logger kaydı yoksa yeni oluştur
        if (!response.ok) {
          this.createLogger(user_id);
        } else {
          const data = await response.json();
          this.logger = data.data;
          console.log('get logger result', this.logger);
        }
    },

    // Logger kayıtlarını güncelle 
    async updateLogger(user_id) {
      const payload = JSON.stringify(this.logger);
      const response = await fetch(`${process.env.VUE_APP_API_URL}/plugin-logs/${user_id}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          },
          body: payload
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        this.getLogger(user_id);
        console.log('update logger result', data);
    },
    // Yeni logger kaydı oluştur
    async createLogger(user_id) {
      const response = await fetch(`${process.env.VUE_APP_API_URL}/plugin-logs`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            avaturn_user_id: user_id
          })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        // Logger kaydını al
        const data = await response.json();
        this.logger = data.data;
        console.log('logger result', data);
    },
    // Kullanıcının avatarlarını Avaturn API'sinden getiren fonksiyon
    async getUserAvatars() {
      try {
        console.log('userId', this.userId)
        const response = await fetch(`${process.env.VUE_APP_AVATURN_URL}/users/${this.userId}/avatars`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('user avatars', data);
        this.userAvatars = data;
        // Avatarları işle  
        await this.processAvatars();
        //this.createLog('view', 'list_avatars');
      } catch (error) {
        console.error('Error making the API call:', error);
      }
    },

    // Kullanıcının avatarlarını işleyen ve gerekli durumlarda render işlemini başlatan fonksiyon
    async processAvatars() {
        // Kullanıcının avatarlarını al
        const userAvatars = this.userAvatars; 
        // Logger'daki avatars dizisini başlat (yoksa boş dizi oluştur)
        this.logger.avatars = this.logger.avatars || []; 
        let renderNeeded = false;
        let resetNeeded = false;
        console.log('loggin avatars', userAvatars, this.logger.avatars)

        // Her bir avatar için kontrol yap
        for (const userAvatar of userAvatars) {
            // Avatar logger'da var mı kontrol et
            const exists = this.logger.avatars.some(loggerAvatar => loggerAvatar.id === userAvatar.id);
            
            // Logger'da yoksa ekle ve render işlemini başlat
            if (!exists) {
                this.logger.avatars.push(userAvatar);
                await this.renderAvatarImage(userAvatar);
                renderNeeded = true;
            } else {
              // Logger'da varsa render_url kontrolü yap
              const logAvatar = this.logger.avatars.filter(loggerAvatar =>loggerAvatar.id === userAvatar.id)[0];
              // render_url yoksa render işlemini başlat
              if (!logAvatar.render_url) {
                  await this.renderAvatarImage(logAvatar);
                  renderNeeded = true;
              }
            }
        }

        // Render veya reset gerekiyorsa işlemleri yap
        if (renderNeeded || resetNeeded) {
            if (resetNeeded) {
              this.logger.avatars = this.userAvatars;
            }
            await this.updateLogger(this.userId);
            this.waitForRender = true;
            this.startRenderCheckInterval();
        }
    },
    // Render url'sini kontrol eden fonksiyon
    async checkRenderUrl(url) {
        try {
            const response = await fetch(url);
            console.log('check render url', response);
            return response.ok; // 'ok' property is true if the response is successful (status in the range 200–299)
        } catch (error) {
            console.error('Error fetching URL:', error);
            return false;
        }
    },
    // Belirli aralıklarla (30 saniyede bir) render işlemlerinin tamamlanıp tamamlanmadığını kontrol eder
    // Eğer tüm avatarların render_url'leri varsa, waitForRender flag'ini false yap ve interval'i temizle
    // Avaturn render işlemlerini callback olarak alır, avaturn panelinden belirlenir. API'de callback metodu mevcuttur.
    startRenderCheckInterval() {
        const intervalId = setInterval(() => {
            this.getLogger(this.userId).then(() => {
                // Tüm avatarların render_url'lerinin olup olmadığını kontrol et
                const allRendered = this.logger.avatars.every(avatar => avatar.render_url);
                console.log('all rendered', allRendered)
                if (allRendered) {
                    // Tüm render işlemleri tamamlandıysa
                    this.waitForRender = false;
                    clearInterval(intervalId); // Interval'i temizle
                    console.log("Tüm avatarların render işlemi tamamlandı.");
                }
            });
        }, 30000); // 30 saniyede bir kontrol et
    },
    /**
     * Avaturn API'sini kullanarak bir avatarı siler
     * @param {Object} avatar - Silinecek avatar objesi
     * @returns {Promise<void>}
     * 
     * 1. Avaturn API'sine DELETE isteği gönderilir
     * 2. İstek başarılı olursa:
     *    - Logger'daki avatar listesinden silinen avatar çıkarılır
     *    - Logger güncellenir
     *    - Kullanıcının avatar listesi yeniden çekilir
     * 3. İstek başarısız olursa hata dönülür
     */
    async deleteAvatar(avatar) {
      const response = await fetch(`${process.env.VUE_APP_AVATURN_URL}/users/${this.userId}/avatars/${avatar.id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        this.logger.avatars = this.logger.avatars.filter(avatar_ => avatar_.id !== avatar.id)
        this.updateLogger(this.userId)
        console.log('delete result', data);
        this.getUserAvatars();
    },

    /**
     * Avaturn API'sini kullanarak bir avatarın görüntüsünü render eder
     * @param {Object} avatar - Render edilecek avatar objesi
     * @returns {Promise<void>}
     * 
     * 1. Avaturn API'sine render isteği gönderilir
     *    - PNG formatında render istenir
     *    - Yarım vücut şeffaf arka plan kullanılır
     * 2. İstek başarılı olursa render işlemi başlatılır
     * 3. İstek başarısız olursa hata fırlatılır
     * 
     * Not: Render işlemi asenkron olarak gerçekleşir, 
     * tamamlanma durumu startRenderCheckInterval() ile kontrol edilir
     */
    async renderAvatarImage(avatar) {
      const response = await fetch(`${process.env.VUE_APP_AVATURN_URL}/renders/new?format=png&avatar_id=${avatar.id}&scene=common/transparent_half_body`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('render result', data);
    },
    /**
     * Avaturn API'sini kullanarak yeni bir oturum oluşturur
     * @param {string} uid - Kullanıcı ID'si 
     * @param {string} avatar_id - Avatar ID'si
     * @returns {Promise<void>}
     * 
     * 1. Yükleme durumu false olarak ayarlanır
     * 2. Avaturn API'sine POST isteği gönderilir:
     *    - Kullanıcı ID'si ve avatar ID'si ile
     *    - Var olan avatarı düzenleme tipi belirtilir
     * 3. İstek başarılı olursa:
     *    - Oturum ID'si ve URL'i kaydedilir
     *    - Görünüm modu 'edit' olarak ayarlanır
     * 4. İstek başarısız olursa hata dönülür
     */
    async createNewSession(uid, avatar_id) {
      try {
        this.isLoading = false;
        const response = await fetch(`${process.env.VUE_APP_AVATURN_URL}/sessions/new`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            user_id: uid,
            config: {
              type: "edit_existing", 
              avatar_id: avatar_id
            }
          })
        });

        if (!response.ok) {
          this.isLoading = false;
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        this.sessionId = data.id;
        this.avaturnUrl = data.url;
        this.setViewMode('edit');
        this.isLoading = false;
        console.log('session id', this.viewMode, data);
      } catch (error) {
        console.error('Error making the API call:', error);
      }
    },

    /**
     * Avaturn API'sini kullanarak yeni bir kullanıcı oluşturur
     * @returns {Promise<void>}
     * 
     * 1. Avaturn API'sine POST isteği gönderilir
     * 2. İstek başarılı olursa:
     *    - Kullanıcı ID'si kaydedilir
     *    - Yeni bir logger oluşturulur
     * 3. İstek başarısız olursa hata dönülür
     */
    async createNewUser() {
      try {
        const response = await fetch(`${process.env.VUE_APP_AVATURN_URL}/users/new`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        this.userId = data.id;
        await this.createLogger(data.id);
      } catch (error) {
        console.error('Error making the API call:', error);
      }
    },
    /**
     * Avatar ve sahne görünümü arasında geçiş yapar
     */
    toggleAvatar() {
      this.fetch = false;
      this.showAvaturn = !this.showAvaturn;
      this.showScene = !this.showScene;
    },
    /**
     * URL'den slug değerini alır
     */
    getSlug() {
      const path = window.location.pathname;
      const segments = path.split('/');
      console.log('segments', segments, window.location);
      return segments.pop() || segments.pop();
    }
  }
}
</script>


<style>
@import "@/assets/css/main.css";
</style>

